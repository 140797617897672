var CookieConsentConstructor = function (options) {
  var parentClass = 'cookie-warning';

  var buildConsentParagraph = function () {
    var f = document.createElement('div');
    f.setAttribute('class', parentClass + '__disclaimer');

    var p = document.createElement('p');
    p.innerHTML = options.message;

    f.appendChild(p);

    return f;
  };

  var buildForm = function () {
    var f = document.createElement('form');
    f.setAttribute('method', 'get');
    f.setAttribute('action', window.location.href);
    f.setAttribute('class', parentClass + '__form');

    var field = buildFormFields(
      options.fields.necessary,
      'cookie_necessary',
      true,
      true
    );
    f.appendChild(field);

    field = buildFormFields(
      options.fields.preferences,
      'cookie_preferences',
      true,
      false
    );
    f.appendChild(field);

    field = buildFormFields(
      options.fields.statistics,
      'cookie_statistics',
      true,
      false
    );
    f.appendChild(field);

    field = buildFormFields(
      options.fields.marketing,
      'cookie_marketing',
      true,
      false
    );
    f.appendChild(field);

    f.appendChild(buildFormButton());

    return f;
  };

  var buildFormFields = function (text, id, checked, disabled) {
    var f = document.createElement("div");
    f.setAttribute('class', parentClass + '__field')

    var i = document.createElement("input");
    i.setAttribute('type', 'checkbox');
    i.setAttribute('id', id);
    i.setAttribute('name', id);
    i.setAttribute('value', 'true');
    i.setAttribute('class', parentClass + '__checkbox');

    if (checked == true) {
      i.setAttribute('checked', 'checked');
    }

    if (disabled == true) {
      i.setAttribute('disabled', 'disabled');
    }

    f.appendChild(i);

    var l = document.createElement("label");
    l.innerHTML = text;
    l.setAttribute('for', id);
    l.setAttribute('class', parentClass + '__label');

    f.appendChild(l);

    return f;
  };

  var buildFormButton = function () {
    var f = document.createElement("div");
    f.setAttribute('class', parentClass + '__actions')

    var i = document.createElement("input");
    i.setAttribute('type', 'submit');
    i.setAttribute('value', options.accept);
    i.setAttribute('class', parentClass + '__button');

    f.appendChild(i);

    return f;
  };

  var wrapContent = function () {
    var f = document.createElement('div');
    f.setAttribute('class', parentClass);

    var inner = document.createElement('div');
    inner.setAttribute('class', parentClass + '__inner');
    f.appendChild(inner);

    var content = document.createElement('div');
    content.setAttribute('class', parentClass + '__content');
    inner.appendChild(content);

    if (options.settings.revision !== undefined) {
      f.dataset.revision = options.settings.revision;
    }

    if (options.settings.requireClick !== undefined) {
      f.dataset.requireClick = options.settings.requireClick;
    }

    if (options.settings.domain !== undefined) {
      f.dataset.domain = options.settings.domain;
    }

    content.appendChild(buildConsentParagraph());
    content.appendChild(buildForm());

    return f;
  };

  this.init = function () {
    var f = wrapContent();
    document.body.insertBefore(f, document.body.firstChild);
  };
};

export default CookieConsentConstructor;
