import $ from 'jquery';
require('slick-carousel/slick/slick');

$(function() {
  $('.js--banners-slick').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    fade: true,
    autoplaySpeed: 4000
  });

  $('.js--testimonials-slick').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000
  });

  $('.js--articles-slick').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000
  });
});

